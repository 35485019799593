import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>
          We build websites, apps, and other such related online productions for businesses and individuals who just need something done online!
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
        <div className="section-title">
          <h3 className="shoutout">
          Easy to work with, no hassle, speaking plain everyday language you can understand, Greenwood Online will help you figure out what you need online, and we’ll get it done right. Reasonable rates keep it real for what you really want to do.
          </h3>
        </div>
      </div>
    </div>
  );
};
